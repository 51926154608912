<template>
  <div>
    <div class="popup d-none d-md-block">
      <div class="container-lg" >
        <div class="row h-100 m-0 justify-content-center">
          <div class="col-12 p-3 p-md-5">
            <div class="popup_inner">
              <div class="overlay"></div>
              <div class="row mx-0 above">
                <div class="col-12 p-0">
                  <div class="row mx-0 justify-content-between">
                    <div class="col-auto p-0">
                      <ios-arrow-round-back-icon class="inline-icon big cursor close" @click="closeItem" v-if="Object.keys(activeLiability).length !== 0" />
                    </div>
                    <div class="col-auto p-0">
                      <ios-close-icon class="inline-icon big cursor close" @click="$emit('close')"/>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-12 bold heading mb-3">
                      Liabilities | {{defaultCurrency}}{{parseFloat(Math.round((zakaatLiabilitiesTotal) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                    </div>
                  </div>
                  <div class="row m-0 justify-content-center d-md-none" v-if="Object.keys(activeLiability).length === 0">
                    <div class="col-sm-6 col-md-3 mb-3" v-for="liability in liabilitiesInfo" :key="liability.link">
                      <LiabilityItem
                        :title="liability[liability.item].title"
                        :info="liability[liability.item].info"
                        :total="liability[liability.item].total"
                        :link="liability[liability.item].link"
                        :type="liability.item"
                        @addItem="showLiabilityType(liability[liability.item])"
                        :active="activeLiability"
                      />
                    </div>
                  </div>
                  <div class="row m-0 justify-content-center d-none d-md-flex">
                    <div class="col-sm-6 col-md-3 mb-3" v-for="liability in liabilitiesInfo" :key="liability.link">
                      <LiabilityItem
                        :title="liability[liability.item].title"
                        :info="liability[liability.item].info"
                        :total="liability[liability.item].total"
                        :link="liability[liability.item].link"
                        :type="liability.item"
                        @addItem="showLiabilityType(liability[liability.item])"
                        :active="activeLiability"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <AddGeneral v-if="activeLiability.link === 'isGeneral'" />
                      <AddHome v-if="activeLiability.link === 'isHome'" />
                      <AddVehicle v-if="activeLiability.link === 'isVehicle'" />
                    </div>
                  </div>
                  <div class="row" v-if="Object.keys(activeLiability).length === 0 ">
                    <div class="col-12">
                      <div class="row" v-if="currentZakaah.zakaatLiabilities.basicLiabilities.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          General Liabilities
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.basicLiabilities" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1">
                              {{liability.description}}
                            </div>
                            <div class="col-12 font12">
                              {{ liability.zakaatBasicLiabilityType }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((liability.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Home Loans
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1">
                              {{liability.description}}
                            </div>
                            <div class="col-12 font12">
                              {{ liability.creditProvider }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Vehicle Finance
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1">
                              {{liability.description}}
                            </div>
                            <div class="col-12 font12">
                              {{ liability.creditProvider }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row mt-5" v-if="zakaatLiabilitiesTotal > 0">
                        <div class="col-12">
                          <Button color="green" btnText="Save Liabilities" icon="arrow" @buttonClicked="save" class="mb-1">
                            <IconArrowForward color="gold" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup d-md-none">
      <div class="row h-100 m-0 justify-content-center">
          <div class="col-12 p-0">
            <div class="popup_inner">
              <div class="row mx-0 env-padding">
                <div class="col-12">
                  <div class="row mx-0 justify-content-between">
                    <div class="col-auto p-0">
                      <ios-arrow-round-back-icon class="inline-icon big cursor close" @click="closeItem" v-if="Object.keys(activeLiability).length !== 0" />
                    </div>
                    <div class="col-auto p-0">
                      <ios-close-icon class="inline-icon big cursor close" @click="$emit('close')"/>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-12 bold heading mb-3">
                      Liabilities | {{defaultCurrency}}{{parseFloat(Math.round((zakaatLiabilitiesTotal) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                    </div>
                  </div>
                  <div class="row m-0 justify-content-center d-md-none" v-if="Object.keys(activeLiability).length === 0">
                    <div class="col-sm-6 col-md-3 mb-3" v-for="liability in liabilitiesInfo" :key="liability.link">
                      <LiabilityItem
                        :title="liability[liability.item].title"
                        :info="liability[liability.item].info"
                        :total="liability[liability.item].total"
                        :link="liability[liability.item].link"
                        :type="liability.item"
                        @addItem="showLiabilityType(liability[liability.item])"
                        :active="activeLiability"
                      />
                    </div>
                  </div>
                  <div class="row mx-0">
                    <div class="col-12">
                      <AddGeneral v-if="activeLiability.link === 'isGeneral'" />
                      <AddHome v-if="activeLiability.link === 'isHome'" />
                      <AddVehicle v-if="activeLiability.link === 'isVehicle'" />
                    </div>
                  </div>
                  <div class="row" v-if="Object.keys(activeLiability).length === 0 ">
                    <div class="col-12">
                      <div class="row" v-if="currentZakaah.zakaatLiabilities.basicLiabilities.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          General Liabilities
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.basicLiabilities" :key="key">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 bold mb-1">
                              {{liability.description}}
                            </div>
                            <div class="col-12 font12">
                              {{ liability.zakaatBasicLiabilityType }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12 text-end bold text-nowrap">
                          {{defaultCurrency}}{{parseFloat(Math.round((liability.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Home Loans
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities" :key="key">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 bold mb-1">
                              {{liability.description}}
                            </div>
                            <div class="col-12 font12">
                              {{ liability.creditProvider }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12 text-end bold text-nowrap">
                          {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Vehicle Finance
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities" :key="key">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 bold mb-1">
                              {{liability.description}}
                            </div>
                            <div class="col-12 font12">
                              {{ liability.creditProvider }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12 text-end bold text-nowrap">
                          {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row my-5" v-if="zakaatLiabilitiesTotal > 0">
                        <div class="col-12 mb-5">
                          <Button color="green" btnText="Save Liabilities" icon="arrow" @buttonClicked="save" class="mb-1">
                            <IconArrowForward color="gold" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    AddGeneral: defineAsyncComponent(() => import('./views/AddGeneral.vue')),
    AddHome: defineAsyncComponent(() => import('./views/AddHome.vue')),
    AddVehicle: defineAsyncComponent(() => import('./views/AddVehicle.vue')),
    LiabilityItem: defineAsyncComponent(() => import('./components/LiabilityItem.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-arrow-round-back-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-round-back.vue'))
  },
  name: 'Liabilities',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      activeLiability: {},
      liabilitiesInfo: [
        {
          item: 'basicLiabilities',
          basicLiabilities: {
            title: 'General Liabilities',
            info: 'You may deduct monies owed by you to others (including institutions). Deduct only what is due and payable today and not future (long-term) debts. Be carefull not to deduct debts already captured in the previous sections.',
            link: 'isGeneral',
            total: 0
          }
        },
        {
          item: 'zakaatHomeLoanLiabilities',
          zakaatHomeLoanLiabilities: {
            title: 'Home Loans',
            info: '',
            link: 'isHome',
            total: 0
          }
        },
        {
          item: 'zakaatVehicleLiabilities',
          zakaatVehicleLiabilities: {
            title: 'Motor Vehicle Finance',
            info: '',
            link: 'isVehicle',
            total: 0
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'currentZakaah',
      'zakaatLiabilitiesTotal'
    ])
  },
  methods: {
    closeItem () {
      this.activeLiability = {}
    },
    showLiabilityType (val) {
      Object.assign(this.activeLiability, val)
    },
    save () {
      this.$emit('close')
      window.scrollTo(0, 0)
    },
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    }
  }
}
</script>
<style scoped>
  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    z-index: 99;
  }
  .popup_inner  {
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    background-color: #FFFFFF;
    position: relative;
    padding: 0;
  }
  .heading {
    color: var( --green-color-dark );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5em;
  }
  .smallheading {
    color: var( --green-color );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .liability_item {
    border: 2px solid var(--green-color-dark);
    border-radius: 15px;
  }
  .close {
    color: var( --green-color );
    font-size: 3rem;
  }
  @media (min-width: 992px) {
    .popup_inner  {
      animation-duration: 1.2s;
      border-radius: 22px 22px 22px 22px;
      box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
      margin: 0;
      height: calc(100vh - 6rem);
      width: 100%;
      overflow: hidden;
      background-color: #FFFFFF;
      background-image: url(../../../../../assets/images/bg.png);
      position: relative;
      padding: 1rem;
    }
    .overlay {
      background-color: transparent;
      background-image: linear-gradient(180deg, #FFFFFFF7 0%, #FFFFFF 100%);
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .above {
      position: relative;
      z-index: 2;
      color: var(--normal-color-dark);
      height: calc(100vh - 6rem - 2rem);
      overflow-y: auto;
      overflow-x: hidden;
    }
    .heading {
      font-size: 44px;
    }
  }
</style>
